.profile{
  margin-bottom: 15px;
}
.content-inside{
  min-height: 1000px;
  padding-bottom: 100px;
}
.admin-tab-content{
  padding: 20px 0;
}
.delete-form{
  display: inline-block;
  vertical-align: top;
}
.filelist{
  margin: 0px;
  padding: 0px;
}
.login_content .form-control{
  line-height: 20px;
}
.btn-green{
  background: #26B99A;
  color: #fff;
  border-radius: 2px;
  padding: 6px 10px;
  border-bottom: 1px solid #169F85;
  i{
    color: #fff;
  }
  &:hover, &:focus{
    background: darken(#26B99A, 10%);
    color: #fff;
  }
}
.datepicker-dropdown:after{
  right: inherit;
}
form label{
  line-height: 34px;
}
.demend-detail label{
  line-height: inherit;
  border-right: 1px solid #ddd;
  font-weight: bold;
}
.filter_form{
  margin-bottom: 25px;
}
.inline_form{
  display: inline-block;
}
.table{
  .inline_form{
    margin-top: 0px;
    &:after{
      display: inline-block;
    }
  }
  .btn{
    margin: 0px 3px;
    i{
      margin-right: 5px;
    }
  }
}
.pagination_tfoot{
  display: none;
  &.active{
    display: table-footer-group;
  }
}
.jambo_table{
  tfoot{
    td{
      background: #f0f0f0;
    }
  }
}

.site_title{
  span{
    font-family: Times;
  }
}
.notice_img{
  margin-bottom: 15px;
}
.imgdel{
  position: absolute;
  top: 10px;
  left: 20px;
}
.img-show{
  margin-bottom: 15px;
}
input[type="file"]{
  line-height: 20px;
}
.v_top{
  .btn{
    vertical-align: top;
  }
}

.table{
  tfoot{
    .pagination{
      margin: 0;
    }
  }
}

.mt50{
  margin-top: 50px !important;
}
.mb40{
  margin-bottom: 40px !important;
}

.filelist{
  li{
    margin: 5px 0;
  }
  .extra{
    position: relative;
    span.del{
      position: absolute;
      top: 7px;
      //right: 10px;
    }
  }
  .product_img_wrapper{
    position: relative;
    img{
      height: 250px;
    }
    span.del{
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}
.picn{
  margin: 8px 0;
}
.main-title{
  margin-bottom: 30px;
  border-bottom: 1px solid #73879C;
  padding-bottom: 5px;
  margin-top: 20px;
  h3{
    color: #73879C;
    margin-top: 40px;
  }
}
.tab-content{
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

body.nav-md .container.body .right_col{
  padding-top: 70px;
}

.logout{
  padding: 100px 20px;
  background: #fff;
  width: 900px;
  margin: 0 auto;
  box-shadow: 1px 1px 3px #000;
}

.list-table{
  thead, tbody{
    tr{
      td, th{
        font-size: 13px;
        padding: 6px;
        vertical-align: middle;
        form{
          margin-bottom: 0px;
        }
      }
    }
  }
}

.form-control{
  line-height:1.42857143;
}

.form-control.transStatus{
  padding: 2px 5px;
  height: 24px;
  line-height: 1;
}